import axios from "axios";
import ase from "crypto-js/aes";
import md5 from "crypto-js/md5";

const aesKey = md5("1xIWioCqFPUY8YmX").toString();

const getEncryptedKey = () =>
  ase.encrypt(+new Date() + "@abczxy123987", aesKey).toString();

export const requestGet = async (
  url,
  { onSuccess, onFail, onError, onFinal, params }
) => {
  const returnValues = {
    success: {},
    fail: {},
    error: {},
    final: {}
  };
  try {
    let response;
    const headers = {
      Authorization: getEncryptedKey()
    };
    if (params)
      response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, {
        params,
        headers
      });
    else
      response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, {
        headers
      });

    const { code, msg, result } = response.data;
    if (code === "000") {
      if (onSuccess) returnValues.success = onSuccess(result);
    } else {
      if (onFail) returnValues.fail = onFail(msg);
      console.warn(msg);
    }
  } catch (error) {
    if (onError) returnValues.error = onError();
    console.error(error);
  } finally {
    if (onFinal) returnValues.final = onFinal();
  }

  return returnValues;
};

export const requestPost = async (
  url,
  data,
  { onSuccess, onFail, onError, onFinal }
) => {
  const returnValues = {
    success: {},
    fail: {},
    error: {},
    final: {}
  };
  try {
    const headers = {
      Authorization: getEncryptedKey()
    };
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${url}`,
      data,
      {
        headers
      }
    );

    const { code, msg, result } = response.data;
    if (code === "000") {
      if (onSuccess) returnValues.success = onSuccess(result);
    } else {
      if (onFail) returnValues.fail = onFail(msg);
      console.warn(msg);
    }
  } catch (error) {
    if (onError) returnValues.error = onError();
    console.error(error);
  } finally {
    if (onFinal) returnValues.final = onFinal();
  }

  return returnValues;
};
