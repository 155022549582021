/* eslint-disable */
import React from "react";
import { Link, withRouter } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Collapse } from "@material-ui/core";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import Info from "@material-ui/icons/Info";
import Public from "@material-ui/icons/Public";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// core components
import styles from "assets/jss/material-kit-pro-react/components/headerStyle.js";
import { useTranslation } from "react-i18next";
import CustomButton from "components/CustomButtons/Button.js";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import queryString from "query-string";
import { requestGet } from "utils/NetworkUtil";

import LOTTERIES from "constants/Lotteries";
import VIET_VIP_LOTTERIES_IDS from "constants/VietVipLotteryIds";

import chinaFlag from "assets/img/flags/China-Flag.svg";
import vietnamFlag from "assets/img/flags/Vietnam-Flag.svg";
import malaysiaFlag from "assets/img/flags/Malaysia-Flag.svg";
import thailandFlag from "assets/img/flags/Thailand-Flag.svg";
import philippineFlag from "assets/img/flags/Philippine-Flag.svg";
import singaporeFlag from "assets/img/flags/Singapore-Flag.svg";
import laosFlag from "assets/img/flags/Laos-Flag.svg";
import liveLotteryFlag from "assets/img/flags/live-lottery.svg";
import locationIcon from "assets/img/location.svg";

import aseanLottoLogo from "assets/img/asean-lotto.png";
import aseanLottoLogo2X from "assets/img/asean-lotto@2x.png";

const COUNTRIES_FLAG = {
  China: chinaFlag,
  Vietnam: vietnamFlag,
  Malaysia: malaysiaFlag,
  Thailand: thailandFlag,
  Singapore: singaporeFlag,
  Philippines: philippineFlag,
  Laos: laosFlag,
  "Live Lotto": liveLotteryFlag
};
const VIET_LOT_DAY = [
  { display: "Mon", value: "MONDAY" },
  { display: "Tue", value: "TUESDAY" },
  { display: "Wed", value: "WEDNESDAY" },
  { display: "Thu", value: "THURSDAY" },
  { display: "Fri", value: "FRIDAY" },
  { display: "Sat", value: "SATURDAY" },
  { display: "Sun", value: "SUNDAY" }
];
const useStyles = makeStyles(styles);

const Header = props => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;

    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, links, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });

  const isRetina = useMediaQuery(
    `only screen and (-webkit-min-device-pixel-ratio: 1.5), 
    only screen and (min--moz-device-pixel-ratio: 1.5), 
    only screen and (-o-min-device-pixel-ratio: 3/2), 
    only screen and (min-device-pixel-ratio: 1.5), 
    only screen and (min-resolution: 192dpi)`
  );
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const { t, i18n } = useTranslation();
  const changeLanguage = lang => () => {
    i18n.changeLanguage(lang);
  };

  React.useEffect(() => {
    const { lang } = queryString.parse(props.location.search);
    if (lang === "en" || lang === "zh" || lang == "vi" || lang == "th") {
      i18n.changeLanguage(lang);
    }
  }, []);

  // * -------- * //
  const [showLocations, setShowLocations] = React.useState(false);
  const [showLiveResults, setShowLiveResults] = React.useState(false);
  const [selectedLiveResults, setSelectedLiveResults] = React.useState(
    "Vietnam"
  );
  const [lotteryList] = React.useState(LOTTERIES);
  React.useEffect(() => {
    props.onLotteriesReceived(lotteryList);
  }, [lotteryList]);

  // React.useEffect(() => {
  //   const fetchLotteryList = async () => {
  //     await requestGet(`lottery/title`, {
  //       onSuccess: result => {
  //         setLotteryList(result);
  //         props.onLotteriesReceived(result);
  //       }
  //     });
  //   };

  //   fetchLotteryList();
  // }, []);

  // * extract lotteries of the country
  const getLotteries = country => {
    const countryLots = lotteryList.find(x => x.classEnName === country);

    return countryLots && countryLots.children instanceof Array
      ? countryLots.children
      : [];
  };

  // * return differet class based on the url
  const getNavClassName = routeName => {
    const { pathname } = props.location;
    if (routeName === pathname && pathname === "/")
      return classes.navLinkActive;
    else if (pathname.indexOf(routeName) > -1 && routeName !== "/")
      return classes.navLinkActive;
    else return classes.navLink;
  };

  // * toggle nav sheet based on sheetName
  const setShowNavSheet = sheetName => {
    switch (sheetName) {
      case "location":
        setShowLocations(!showLocations);
        break;

      case "live":
        setShowLiveResults(!showLiveResults);
        break;

      default:
        break;
    }
  };
  const handleNavSheet = sheetName => {
    if (showLocations || showLiveResults) {
      setShowLocations(false);
      setShowLiveResults(false);
      setTimeout(() => {
        setShowNavSheet(sheetName);
      }, 350);
    } else {
      setShowNavSheet(sheetName);
    }
  };

  // * util function for checking two array is equal
  const arraysEqual = (arr1, arr2) => {
    if (
      !Array.isArray(arr1) ||
      !Array.isArray(arr2) ||
      arr1.length !== arr2.length
    )
      return false;

    const a1 = arr1.concat().sort();
    const a2 = arr2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
      if (a1[i] !== a2[i]) return false;
    }
    return true;
  };

  // * Partial Renders Declarations
  const { country } = props;
  const responsiveLanguageBtnProps = {};
  if (!isSmDown) responsiveLanguageBtnProps.buttonIcon = Public;
  const headerLinks = (
    <List className={classes.list}>
      <Hidden smDown>
        <ListItem className={classes.listItem + " " + classes.locationListItem}>
          <CustomButton
            round
            color="primary"
            className={classes.navLink + " " + classes.locationButton}
            onClick={() => handleNavSheet("location")}
          >
            <div className={classes.flagWithName}>
              {country ? (
                <img
                  src={COUNTRIES_FLAG[country]}
                  alt={country}
                  className={classes.countryFlag}
                />
              ) : (
                <img src={locationIcon} />
              )}
              {country ? t(country) : t("Location")}
            </div>
            <ExpandMoreIcon
              className={classNames(classes.expandIcon, {
                expanded: showLocations
              })}
            />
          </CustomButton>
        </ListItem>
      </Hidden>
      <ListItem className={classes.listItem + " " + classes.mlAuto}>
        <CustomButton
          className={getNavClassName("/")}
          color="transparent"
          component={Link}
          to="/"
        >
          {t("Home")}
        </CustomButton>
      </ListItem>
      {/* For mobile */}
      <Hidden mdUp>
        <ListItem className={classes.listItem + " " + classes.locationListItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={"primary"}
            buttonText={t("Live Results")}
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            dropdownList={Array.from(lotteryList, country => (
              <CustomButton
                className={classes.navLink}
                color="transparent"
                component={Link}
                to={`/lotteries/${country.classEnName}`}
                onClick={() => setMobileOpen(false)}
              >
                {/* {t("backend:" + country.classEnName + " Lottery")} */}
                {t("" + country.classEnName)}
                {country.promotionIcon && (
                  <PromotionIcon icon={country.promotionIcon} />
                )}
              </CustomButton>
            ))}
          />
        </ListItem>
      </Hidden>
      <Hidden smDown>
        <ListItem className={classes.listItem}>
          <CustomButton
            className={getNavClassName("/live-results")}
            color="transparent"
            onClick={() => handleNavSheet("live")}
          >
            {t("Live Results")}
            <ExpandMoreIcon
              className={classNames(classes.expandIcon, {
                expanded: showLiveResults
              })}
            />
          </CustomButton>
          {/* <Hidden mdUp>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={"primary"}
            buttonText={t("Live Results")}
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            dropdownList={Array.from(lotteryList, (lottery, idx) => (
              <CustomButton
                key={idx}
                className={classes.navLink}
                color="transparent"
                component={Link}
                to={`/lotteries/${lottery.classEnName}`}
                onClick={() => setMobileOpen(false)}
              >
                {t(lottery.classEnName) + " Lottery"}
              </CustomButton>
            ))}
          />
        </Hidden> */}
        </ListItem>
      </Hidden>
      <ListItem className={classes.listItem}>
        {/* <CustomButton
          className={getNavClassName('/partners')}
          color="transparent"
          component={Link}
          to="/partners/country/all"
        >
          {t('Partners')}
        </CustomButton> */}
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={"primary"}
          buttonText={t("Partners")}
          buttonProps={{
            className: getNavClassName("/partners"),
            color: "transparent"
          }}
          dark={true}
          noScroll={true}
          caret={false}
          dropdownList={[
            <CustomButton
              className={classes.navLink}
              style={{ width: "100%" }}
              color="transparent"
              component={Link}
              to="/partners/country/all"
            >
              {t("Platforms")}
            </CustomButton>,
            <CustomButton
              className={classes.navLink}
              style={{ width: "100%" }}
              color="transparent"
              component={Link}
              to="/partners/payment/f-pay"
            >
              F-Pay
            </CustomButton>,
            <CustomButton
              className={classes.navLink}
              style={{ width: "100%" }}
              color="transparent"
              component={Link}
              to="/partners/loto-ph"
            >
              Loto.ph
            </CustomButton>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={"primary"}
          buttonText={t("API")}
          buttonProps={{
            className: getNavClassName("/lottery-api"),
            color: "transparent"
          }}
          dark={true}
          noScroll={true}
          caret={false}
          dropdownList={[
            <CustomButton
              className={classes.navLink}
              style={{ width: "100%" }}
              color="transparent"
              component={Link}
              to="/lottery-api"
            >
              {t("Results API")}
            </CustomButton>,

            <CustomButton
              className={classes.navLink}
              style={{ width: "100%" }}
              color="transparent"
              component={Link}
              to="/partners/loto-ph"
            >
              LOTO API
            </CustomButton>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomButton
          className={getNavClassName("/contact-us")}
          color="transparent"
          component={Link}
          to="/contact-us"
        >
          {t("Contact Us")}
        </CustomButton>
      </ListItem>
      <ListItem className={classes.listItem + " " + classes.mlAuto}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={"primary"}
          buttonText={t("Language")}
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          {...responsiveLanguageBtnProps}
          dropdownList={[
            <CustomButton
              className={classes.navLink}
              style={{ width: "100%" }}
              color="transparent"
              onClick={changeLanguage("en")}
            >
              {/* {t("English")} */}
              English
            </CustomButton>,
            <CustomButton
              className={classes.navLink}
              style={{ width: "100%" }}
              color="transparent"
              onClick={changeLanguage("zh")}
            >
              {/* {t("Chinese")} */}
              中文
            </CustomButton>,
            <CustomButton
              className={classes.navLink}
              style={{ width: "100%" }}
              color="transparent"
              onClick={changeLanguage("vi")}
            >
              {/* {t("Vietnamese")} */}
              Tiếng Việt
            </CustomButton>,
            <CustomButton
              className={classes.navLink}
              style={{ width: "100%" }}
              color="transparent"
              onClick={changeLanguage("th")}
            >
              {/* {t("Thai")} */}
              ไทย
            </CustomButton>
          ]}
        />
      </ListItem>
    </List>
  );

  const locations = (
    <Collapse
      in={showLocations}
      timeout={350}
      className={classes.navSheetLocation}
    >
      <List className={classes.locationList}>
        {lotteryList.map(country => (
          <ListItem key={country.classId}>
            <CustomButton
              className={classes.navLink + " " + classes.countryButton}
              color="transparent"
              component={Link}
              to={`/lotteries/${country.classEnName}`}
            >
              <img
                src={COUNTRIES_FLAG[country.classEnName]}
                alt={country.classEnName}
                className={classes.countryFlag}
              />
              {t("" + country.classEnName)}
            </CustomButton>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  const [vietLotType, setVietLotType] = React.useState("VIP Lottery");
  const liveResultLinks = (
    <Collapse in={showLiveResults} timeout={350} className={classes.navSheet}>
      <div className={classes.liveResultMenuWrapper}>
        <div className={classes.liveResultCountries}>
          <List className={classes.rightAlignList} style={{ paddingTop: "0" }}>
            {lotteryList.map((lottery, idx) => (
              <ListItem
                key={lottery.classId}
                className={
                  classes.navSheetItem +
                  (lottery.classEnName === selectedLiveResults ? " active" : "")
                }
              >
                <img
                  src={COUNTRIES_FLAG[lottery.classEnName]}
                  alt={lottery.classEnName}
                  className={classes.liveResultCountryFlag}
                />
                <Link
                  to={`/lotteries/${lottery.classEnName}`}
                  className={
                    classes.navSheetLink +
                    (lottery.classEnName === selectedLiveResults
                      ? " active"
                      : "")
                  }
                  onMouseEnter={() =>
                    setSelectedLiveResults(lottery.classEnName)
                  }
                  onClick={() => setShowLiveResults(false)}
                >
                  {t("" + lottery.classEnName)}
                  {lottery.promotionIcon && (
                    <PromotionIcon icon={lottery.promotionIcon} />
                  )}
                  <ChevronRightIcon style={{ marginLeft: "auto" }} />
                </Link>
              </ListItem>
            ))}
          </List>
        </div>
        <div className={classes.liveResultLotteries}>
          {selectedLiveResults === "Vietnam" && (
            <List className={classes.lotteryTypeMenu}>
              <ListItem>
                <span
                  onClick={() => setVietLotType("VIP Lottery")}
                  className={vietLotType === "VIP Lottery" ? "active" : ""}
                >
                  {t("VIP Lottery")}
                </span>
              </ListItem>
              <ListItem>
                <span
                  onClick={() => setVietLotType("South Lottery")}
                  className={vietLotType === "South Lottery" ? "active" : ""}
                >
                  {t("Southern Lottery")}
                </span>
              </ListItem>
              <ListItem>
                <span
                  onClick={() => setVietLotType("Central Lottery")}
                  className={vietLotType === "Central Lottery" ? "active" : ""}
                >
                  {t("Central Lottery")}
                </span>
              </ListItem>
              <ListItem>
                <span
                  onClick={() => setVietLotType("North Lottery")}
                  className={vietLotType === "North Lottery" ? "active" : ""}
                >
                  {t("Northern Lottery")}
                </span>
              </ListItem>
              <ListItem>
                <span
                  onClick={() => setVietLotType("Vietlott")}
                  className={vietLotType === "Vietlott" ? "active" : ""}
                >
                  {t("Vietlott")}
                </span>
              </ListItem>
            </List>
          )}
          <List
            className={
              classes.multiColumnList +
              (selectedLiveResults === "Vietnam" ? " withDay" : "")
            }
            style={{ paddingRight: 0 }}
          >
            {selectedLiveResults !== "Vietnam" &&
              getLotteries(selectedLiveResults).map((lot, key) => (
                <ListItem key={key}>
                  <Link
                    to={`/live-results/${lot.lotteryEnName
                      .toLowerCase()
                      .replace(/\s/g, "-")
                      .replace("/", "-")}`}
                    className={classes.navSheetLink}
                    onClick={() => setShowLiveResults(false)}
                  >
                    {t("backend:" + lot.lotteryEnName)}
                  </Link>
                </ListItem>
              ))}

            {selectedLiveResults === "Vietnam" && (
              <>
                <ListItem className="nestedLi">
                  <List className={classes.horizontalList}>
                    {getLotteries(selectedLiveResults).map(
                      (lot, lotIdx) =>
                        arraysEqual(
                          lot.weekDay,
                          VIET_LOT_DAY.map(day => day.value)
                        ) &&
                        (vietLotType !== "VIP Lottery"
                          ? lot.classType.value === vietLotType &&
                            !VIET_VIP_LOTTERIES_IDS.includes(lot.lotteryId)
                          : VIET_VIP_LOTTERIES_IDS.includes(lot.lotteryId)) && (
                          <ListItem key={lotIdx} style={{ paddingRight: 0 }}>
                            <Link
                              to={`/live-results/${lot.lotteryEnName
                                .toLowerCase()
                                .replace(/\s/g, "-")
                                .replace("/", "-")}`}
                              className={classes.navSheetLink}
                              onClick={() => setShowLiveResults(false)}
                            >
                              {t("backend:" + lot.lotteryEnName)}
                            </Link>
                          </ListItem>
                        )
                    )}
                  </List>
                </ListItem>
                {VIET_LOT_DAY.map(day => (
                  <ListItem key={day.value} className="nestedLi">
                    <List className={classes.horizontalList}>
                      {getLotteries(selectedLiveResults).map(
                        (lot, lotIdx) =>
                          lot.weekDay.includes(day.value) &&
                          !arraysEqual(
                            lot.weekDay,
                            VIET_LOT_DAY.map(day => day.value)
                          ) &&
                          (vietLotType !== "VIP Lottery"
                            ? lot.classType.value === vietLotType &&
                              !VIET_VIP_LOTTERIES_IDS.includes(lot.lotteryId)
                            : VIET_VIP_LOTTERIES_IDS.includes(
                                lot.lotteryId
                              )) && (
                            <ListItem key={lotIdx}>
                              <Link
                                to={`/live-results/${lot.lotteryEnName
                                  .toLowerCase()
                                  .replace(/\s/g, "-")
                                  .replace("/", "-")}`}
                                className={classes.navSheetLink}
                                onClick={() => setShowLiveResults(false)}
                              >
                                {t("backend:" + lot.lotteryEnName)}
                              </Link>
                            </ListItem>
                          )
                      )}
                    </List>
                  </ListItem>
                ))}
              </>
            )}
            {selectedLiveResults === "Vietnam" && (
              <div className={classes.daySelect}>
                <List>
                  <ListItem>
                    <span style={{ whiteSpace: "nowrap" }}>{t("All-s")}</span>
                  </ListItem>
                  {vietLotType !== "VIP Lottery" &&
                    VIET_LOT_DAY.map(day => (
                      <ListItem key={day.value}>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {t(day.display + "-s")}
                        </span>
                      </ListItem>
                    ))}
                </List>
              </div>
            )}
          </List>
          {/* (lot.classType.value === vietLotType &&
                  (vietLotDay === "ALL" ||
                    lot.weekDay.includes(vietLotDay))) */}
        </div>
        {/* <List>
            {LIVE_RESULT_LINKS[selectedLiveResults].map((lot, k) => (
              <ListItem key={k}>{lot.name}</ListItem>
            ))}
          </List> */}
      </div>
    </Collapse>
  );
  // * End of Partial Renders Declarations

  return (
    <AppBar
      className={appBarClasses}
      onMouseLeave={() => {
        setShowLocations(false);
        setShowLiveResults(false);
      }}
    >
      <Toolbar className={classes.containerFluid}>
        <Button className={classes.title}>
          <Link to="/">
            <img
              className={classes.brandLogo}
              src={isRetina ? aseanLottoLogo2X : aseanLottoLogo}
              alt="ASEAN LOTTO"
            />
          </Link>
        </Button>
        <Hidden smDown implementation="css" className={classes.hidden}>
          <div className={classes.collapse}>{headerLinks}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden smDown>
        {locations}
        {liveResultLinks}
      </Hidden>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton> */}
          <div className={classes.appResponsive}>{headerLinks}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};
const PromotionIcon = ({ icon }) => {
  return (
    <img
      src={icon}
      width="40"
      height="30"
      style={{ marginLeft: "3px", marginTop: "-10px" }}
    />
  );
};
Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
    "darkTransparent"
  ]),
  links: PropTypes.node,
  brand: PropTypes.node,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

export default withRouter(Header);
