import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import Header from "components/Header/Header";
import routes from "./constants/routes";

import logo from "assets/img/asian-logo.png";

var hist = createBrowserHistory();

const MainLayout = () => {
  const [allLotteries, setAllLotteries] = React.useState([]);
  const [countryName, setCountryName] = React.useState("");

  const onLotteriesReceived = lotteries => setAllLotteries(lotteries);
  const syncCountryName = name => setCountryName(name);

  return (
    <Router history={hist}>
      <Header
        color="darkTransparent"
        brand={<img src={logo} alt="ASEAN LOTTO" />}
        fixed
        changeColorOnScroll={{
          color: "primary",
          height: 300
        }}
        onLotteriesReceived={onLotteriesReceived}
        country={countryName}
      />
      <Switch>
        {allLotteries.length > 0 &&
          routes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              render={() => (
                <route.component
                  allLotteries={allLotteries}
                  syncCountryName={syncCountryName}
                />
              )}
            />
          ))}
      </Switch>
    </Router>
  );
};

export default MainLayout;
