const vietVipLotteriesIds = [
  "839e75dfd81e413c9788dfe23ecb36da",
  "639dc233e1fe4dfc8cb38df314620c19",
  "0cbc5205ee1245929757ad566ac1f1c0",
  "04c17f151e014310ba978aae8075de98",
  "972f313ddebc49929104caa25787c3db",
  "5a8c9f90f02249f9811596bcc3581e4f",
  "c98c1a677dc541e88a3f8acb72e0852d"
];

export default vietVipLotteriesIds;
