import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    ns: [
      "common",
      "lotDesc",
      "home",
      "live",
      "history",
      "api",
      "partner",
      "contact",
      "backend",
      "country",
      "trending",
      "fPay",
      "lotoPh"
    ],
    defaultNS: "common",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
