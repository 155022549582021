import locationIcon from "assets/img/new.png";
const lotteries = [
  {
    classId: "493b39e774234230bc22682aa052c75c",
    className: "越南",
    classEnName: "Vietnam",
    children: [
      {
        lotteryId: "839e75dfd81e413c9788dfe23ecb36da",
        lotteryName: "Ho Chi Minh VIP",
        lotteryEnName: "Ho Chi Minh VIP",
        lotteryEnumName: "Ho Chi Minh VIP",
        logo:
          "https://em.aseanlotto.com//image/2020/03/0c218762a90d4f5ab1b042d2b9b818ce.png",
        description: "Lottery-HoChiMinhVIP-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      },
      {
        lotteryId: "639dc233e1fe4dfc8cb38df314620c19",
        lotteryName: "Hanoi VIP",
        lotteryEnName: "Hanoi VIP",
        lotteryEnumName: "Hanoi VIP",
        logo:
          "https://em.aseanlotto.com//image/2020/03/97f1b634423840aba2fd6db9bd260d7e.png",
        description: "Lottery-HaNoiVip-Description",
        classType: { label: "北方彩种", value: "North Lottery" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      },
      {
        lotteryId: "d0c1deeffee74cc1b4bc476170560e95",
        lotteryName: "Ho Chi Minh",
        lotteryEnName: "Ho Chi Minh",
        lotteryEnumName: "Ho Chi Minh",
        logo:
          "https://em.aseanlotto.com//image/2020/03/fc814f3845b845d6b8bb21b2bdaca250.png",
        description: "Lottery-HoChiMinh-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["SATURDAY", "MONDAY"]
      },
      {
        lotteryId: "c7e2e5c12290412083906b4ea9cdb41b",
        lotteryName: "Ca Mau",
        lotteryEnName: "Ca Mau",
        lotteryEnumName: "Ca Mau",
        logo:
          "https://em.aseanlotto.com//image/2020/03/b39595450d58469e8dae2c42c5ab9750.png",
        description: "Lottery-CaMau-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["MONDAY"]
      },
      {
        lotteryId: "c7ffc2666e9742698f4d398bb0983272",
        lotteryName: "Dong Thap",
        lotteryEnName: "Dong Thap",
        lotteryEnumName: "Dong Thap",
        logo:
          "https://em.aseanlotto.com//image/2020/03/0a8ab930bb554bc7a136edb35b17483e.png",
        description: "Lottery-DongThap-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["MONDAY"]
      },
      {
        lotteryId: "4ec441f5bc294b01bcc992d3730e39cb",
        lotteryName: "Bac Lieu",
        lotteryEnName: "Bac Lieu",
        lotteryEnumName: "Bac Lieu",
        logo:
          "https://em.aseanlotto.com//image/2020/03/f6ad21e4386c412da1d393afbf0fb67e.png",
        description: "Lottery-BacLieu-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["TUESDAY"]
      },
      {
        lotteryId: "81be4efa2b9a47a9aeb635a222777aed",
        lotteryName: "Dong Nai",
        lotteryEnName: "Dong Nai",
        lotteryEnumName: "Dong Nai",
        logo:
          "https://em.aseanlotto.com//image/2020/03/88fc91baa63a4b6ca3562ee1413e4507.png",
        description: "Lottery-DongNai-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY"]
      },
      {
        lotteryId: "c6756d8a411547f488a053648e2a651c",
        lotteryName: "An Giang",
        lotteryEnName: "An Giang",
        lotteryEnumName: "An Giang",
        logo:
          "https://em.aseanlotto.com//image/2020/03/c80f954d3d81412a9f09c156e9cdf304.png",
        description: "Lottery-AnGiang-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["THURSDAY"]
      },
      {
        lotteryId: "341c51dadce349ce9add235ed20f17bf",
        lotteryName: "Binh Duong",
        lotteryEnName: "Binh Duong",
        lotteryEnumName: "Binh Duong",
        logo:
          "https://em.aseanlotto.com//image/2020/03/1acd62317b9246b9b633a5e10b1efecd.png",
        description: "Lottery-BinhDuong-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["FRIDAY"]
      },
      {
        lotteryId: "a0e833e84ed44e1284612f789e0f74cd",
        lotteryName: "Binh Phuoc",
        lotteryEnName: "Binh Phuoc",
        lotteryEnumName: "Binh Phuoc",
        logo:
          "https://em.aseanlotto.com//image/2020/03/4aaa740263e2443fb6841518acd644ad.png",
        description: "Lottery-BinhPhuoc-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["SATURDAY"]
      },
      {
        lotteryId: "0cbc5205ee1245929757ad566ac1f1c0",
        lotteryName: "DaNang VIP",
        lotteryEnName: "DaNang VIP",
        lotteryEnumName: "DaNang VIP",
        logo:
          "https://em.aseanlotto.com//image/2021/04/9eec06e74efd4019b465fb3b3ecd2bd9.png",
        description: "Lottery-DaNangVip-Description",
        classType: {
          label: "中央彩票",
          value: "Central Lottery"
        },
        isOpenToday: true,
        weekDay: [
          "THURSDAY",
          "FRIDAY",
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "SATURDAY",
          "SUNDAY"
        ],
        isLiveLotto: false
      },
      {
        lotteryId: "04c17f151e014310ba978aae8075de98",
        lotteryName: "SaiGon VIP",
        lotteryEnName: "SaiGon VIP",
        lotteryEnumName: "SaiGon VIP",
        logo:
          "https://em.aseanlotto.com//image/2021/04/fd8278b138484627b726a9c776a28ee9.png",
        description: "Lottery-SaiGonVIP-Description",
        classType: {
          label: "中央彩票",
          value: "Central Lottery"
        },
        isOpenToday: true,
        weekDay: [
          "THURSDAY",
          "FRIDAY",
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "SATURDAY",
          "SUNDAY"
        ],
        isLiveLotto: false
      },
      {
        lotteryId: "6c31dd93033c48878f58e6988b19e6f2",
        lotteryName: "Ben Tre",
        lotteryEnName: "Ben Tre",
        lotteryEnumName: "Ben Tre",
        logo:
          "https://em.aseanlotto.com//image/2020/03/3ec5cbc55a8343739d4b74ff38ac0055.png",
        description: "Lottery-BenTre-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["TUESDAY"]
      },
      {
        lotteryId: "4d23593a80244097a01380aa7b88a450",
        lotteryName: "Vung Tau",
        lotteryEnName: "Vung Tau",
        lotteryEnumName: "Vung Tau",
        logo:
          "https://em.aseanlotto.com//image/2020/03/e97fd654326d44008011c1b864baa351.png",
        description: "Lottery-VungTau-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["TUESDAY"]
      },
      {
        lotteryId: "c38eb4e1711e4170b27af2f8a5d7eee1",
        lotteryName: "Can Tho",
        lotteryEnName: "Can Tho",
        lotteryEnumName: "Can Tho",
        logo:
          "https://em.aseanlotto.com//image/2020/03/b38cc76abc9e4a5dacd466e1548fe0fd.png",
        description: "Lottery-CanTho-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY"]
      },
      {
        lotteryId: "27e0eac75222452693f12fb88199fcc6",
        lotteryName: "Soc Trang",
        lotteryEnName: "Soc Trang",
        lotteryEnumName: "Soc Trang",
        logo:
          "https://em.aseanlotto.com//image/2020/03/8913ae9c8f94464aae1b9360e3d73884.png",
        description: "Lottery-SocTrang-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY"]
      },
      {
        lotteryId: "776dbf43d33f49078b8f8c275adee3b2",
        lotteryName: "Tay Ninh",
        lotteryEnName: "Tay Ninh",
        lotteryEnumName: "Tay Ninh",
        logo:
          "https://em.aseanlotto.com//image/2020/03/9c1afef462184bfc829a6be9b71fd488.png",
        description: "Lottery-TayNinh-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["THURSDAY"]
      },
      {
        lotteryId: "abe047dd0d3f468c8f67dd308e1030a1",
        lotteryName: "Binh Thuan",
        lotteryEnName: "Binh Thuan",
        lotteryEnumName: "Binh Thuan",
        logo:
          "https://em.aseanlotto.com//image/2020/03/f4ecfe5ef46849ae858ff25bf49ec78d.png",
        description: "Lottery-BinhThuan-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["THURSDAY"]
      },
      {
        lotteryId: "b9247e5bfc6d433099a89c4494a2e6bc",
        lotteryName: "Vinh Long",
        lotteryEnName: "Vinh Long",
        lotteryEnumName: "Vinh Long",
        logo:
          "https://em.aseanlotto.com//image/2020/03/4a1432d9ad8940018d650672ef5cb13e.png",
        description: "Lottery-VinhLong-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["FRIDAY"]
      },
      {
        lotteryId: "df7d5ff4c5184f338024d5c6d53b563f",
        lotteryName: "Tra Vinh",
        lotteryEnName: "Tra Vinh",
        lotteryEnumName: "Tra Vinh",
        logo:
          "https://em.aseanlotto.com//image/2020/03/3522c992f16044dfa6a599842410f505.png",
        description: "Lottery-TraVinh-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["FRIDAY"]
      },
      {
        lotteryId: "376b32864e764ce086cab6afca6cf3fb",
        lotteryName: "Long An",
        lotteryEnName: "Long An",
        lotteryEnumName: "Long An",
        logo:
          "https://em.aseanlotto.com//image/2020/03/0aa12e67245b4862ac928986edad8db9.png",
        description: "Lottery-LongAn-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["SATURDAY"]
      },
      {
        lotteryId: "8e57338c3ede42f1818ed693d3178754",
        lotteryName: "Hau Giang",
        lotteryEnName: "Hau Giang",
        lotteryEnumName: "Hau Giang",
        logo:
          "https://em.aseanlotto.com//image/2020/03/baf4bfac80b24956b2b68913cf4d922e.png",
        description: "Lottery-HauGiang-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["SATURDAY"]
      },
      {
        lotteryId: "0f78281253174bea9f7cfdcbd2e5c4cc",
        lotteryName: "Tien Giang",
        lotteryEnName: "Tien Giang",
        lotteryEnumName: "Tien Giang",
        logo:
          "https://em.aseanlotto.com//image/2020/03/217e222bf7bb4cebaaae0317bf52c5f0.png",
        description: "Lottery-TienGiang-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["SUNDAY"]
      },
      {
        lotteryId: "2aa15ce8ff3546cbb707e2423b09718c",
        lotteryName: "Kien Giang",
        lotteryEnName: "Kien Giang",
        lotteryEnumName: "Kien Giang",
        logo:
          "https://em.aseanlotto.com//image/2020/03/3c1ffc292a804801a8700c8ae1d2b7ab.png",
        description: "Lottery-KienGiang-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["SUNDAY"]
      },
      {
        lotteryId: "3e2c2d3d43894bf190f89da02fd91731",
        lotteryName: "Da Lat",
        lotteryEnName: "Da Lat",
        lotteryEnumName: "Da Lat",
        logo:
          "https://em.aseanlotto.com//image/2020/03/54eaee73c7be4f90a01b229bb8445b2e.png",
        description: "Lottery-DaLat-Description",
        classType: { label: "南方彩票", value: "South Lottery" },
        isOpenToday: false,
        weekDay: ["SUNDAY"]
      },
      {
        lotteryId: "e18689c47f45473dbd18bae0198c593b",
        lotteryName: "Hue",
        lotteryEnName: "Hue",
        lotteryEnumName: "Hue",
        logo:
          "https://em.aseanlotto.com//image/2020/03/6bff5247960042e8b872800614f8ff93.png",
        description: "Lottery-Hue-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["SUNDAY", "MONDAY"]
      },
      {
        lotteryId: "431e01e4983748e4a2b7f385628fe617",
        lotteryName: "Phu Yen",
        lotteryEnName: "Phu Yen",
        lotteryEnumName: "Phu Yen",
        logo:
          "https://em.aseanlotto.com//image/2020/03/98ce843457784c099bcb30071d35107b.png",
        description: "Lottery-PhuYen-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["MONDAY"]
      },
      {
        lotteryId: "a42996c665f045e98242078c8411e20f",
        lotteryName: "Dac Lac",
        lotteryEnName: "Dac Lac",
        lotteryEnumName: "Dac Lac",
        logo:
          "https://em.aseanlotto.com//image/2020/03/bde4fda5cf004c44b115a5c46e84c2f1.png",
        description: "Lottery-DacLac-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["TUESDAY"]
      },
      {
        lotteryId: "1ed940392971475999ae1860668cbcf9",
        lotteryName: "Quang Nam",
        lotteryEnName: "Quang Nam",
        lotteryEnumName: "Quang Nam",
        logo:
          "https://em.aseanlotto.com//image/2020/03/89acfdea515d47d4b52f95374c4d39e3.png",
        description: "Lottery-QuangNam-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["TUESDAY"]
      },
      {
        lotteryId: "78f08bb5a31142298f70425cbd8a199c",
        lotteryName: "Da Nang",
        lotteryEnName: "Da Nang",
        lotteryEnumName: "Da Nang",
        logo:
          "https://em.aseanlotto.com//image/2020/03/21877e08b24b4ce4887c6619081a7151.png",
        description: "Lottery-DaNang-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY", "SATURDAY"]
      },
      {
        lotteryId: "c17f654f1552467480fdb5a0e48df263",
        lotteryName: "Khanh Hoa",
        lotteryEnName: "Khanh Hoa",
        lotteryEnumName: "Khanh Hoa",
        logo:
          "https://em.aseanlotto.com//image/2020/03/600bd70b74ed404c9b07c82e3287297e.png",
        description: "Lottery-KhanhHoa-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY", "SUNDAY"]
      },
      {
        lotteryId: "666bd05ac54842a68328411c2a964b90",
        lotteryName: "Binh Dinh",
        lotteryEnName: "Binh Dinh",
        lotteryEnumName: "Binh Dinh",
        logo:
          "https://em.aseanlotto.com//image/2020/03/3c507e76575546fc8d4640b470c692d4.png",
        description: "Lottery-BinhDinh-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["THURSDAY"]
      },
      {
        lotteryId: "291e7ff1fec541c388958fd74ef72bd9",
        lotteryName: "Quang Tri",
        lotteryEnName: "Quang Tri",
        lotteryEnumName: "Quang Tri",
        logo:
          "https://em.aseanlotto.com//image/2020/03/728a616fc3b24b198b735930b305418a.png",
        description: "Lottery-QuangTri-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["THURSDAY"]
      },
      {
        lotteryId: "d66265eb1ac04fc09cd2da12a1f9ca53",
        lotteryName: "Quang Binh",
        lotteryEnName: "Quang Binh",
        lotteryEnumName: "Quang Binh",
        logo:
          "https://em.aseanlotto.com//image/2020/03/14b975ef5e764b578bd6dce95ec35a07.png",
        description: "Lottery-QuangBinh-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["THURSDAY"]
      },
      {
        lotteryId: "f04e6787bb2942edba598184c2372d78",
        lotteryName: "Gia Lai",
        lotteryEnName: "Gia Lai",
        lotteryEnumName: "Gia Lai",
        logo:
          "https://em.aseanlotto.com//image/2020/03/568fdc9b278c42429da7365509cfb123.png",
        description: "Lottery-GiaLai-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["FRIDAY"]
      },
      {
        lotteryId: "4d660ec0952542b5831ffd61186cd394",
        lotteryName: "Ninh Thuan",
        lotteryEnName: "Ninh Thuan",
        lotteryEnumName: "Ninh Thuan",
        logo:
          "https://em.aseanlotto.com//image/2020/03/b334cf0c501f458f94786a476216f850.png",
        description: "Lottery-NinhThuan-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["FRIDAY"]
      },
      {
        lotteryId: "ea6a53c694a44b9e9852004bbfd10672",
        lotteryName: "Quang Ngai",
        lotteryEnName: "Quang Ngai",
        lotteryEnumName: "Quang Ngai",
        logo:
          "https://em.aseanlotto.com//image/2020/03/832688bb88ca4005ba8e170348f25800.png",
        description: "Lottery-QuangNgai-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["SATURDAY"]
      },
      {
        lotteryId: "558a4954f5d041958b2cfeb41244643b",
        lotteryName: "Dac Nong",
        lotteryEnName: "Dac Nong",
        lotteryEnumName: "Dac Nong",
        logo:
          "https://em.aseanlotto.com//image/2020/03/8b10f224039a41a087ee98aa8ad3500d.png",
        description: "Lottery-DacNong-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["SATURDAY"]
      },
      {
        lotteryId: "ce89bdc713554c51b3342af1d2f78069",
        lotteryName: "Kon Tum",
        lotteryEnName: "Kon Tum",
        lotteryEnumName: "Kon Tum",
        logo:
          "https://em.aseanlotto.com//image/2020/03/eaa76b2d74844de598d017c477e06e53.png",
        description: "Lottery-KonTum-Description",
        classType: { label: "中央彩票", value: "Central Lottery" },
        isOpenToday: false,
        weekDay: ["SUNDAY"]
      },
      {
        lotteryId: "972f313ddebc49929104caa25787c3db",
        lotteryName: "Vietnam VIP",
        lotteryEnName: "Vietnam VIP",
        lotteryEnumName: "Vietnam VIP",
        logo:
          "https://em.aseanlotto.com//image/2021/04/9065d27408dc49afbc8f391aca5bbf4d.png",
        description: "Lottery-VietnamVIP-Description",
        classType: {
          label: "北方彩种",
          value: "North Lottery"
        },
        isOpenToday: true,
        weekDay: [
          "THURSDAY",
          "FRIDAY",
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "SATURDAY",
          "SUNDAY"
        ],
        isLiveLotto: false
      },
      {
        lotteryId: "5a8c9f90f02249f9811596bcc3581e4f",
        lotteryName: "Thang Long VIP",
        lotteryEnName: "Thang Long VIP",
        lotteryEnumName: "Thang Long VIP",
        logo:
          "https://em.aseanlotto.com//image/2021/04/895f784c62494572980f68e8234b8e1d.png",
        description: "Lottery-ThangLongVIP-Description",
        classType: {
          label: "北方彩种",
          value: "North Lottery"
        },
        isOpenToday: true,
        weekDay: [
          "THURSDAY",
          "FRIDAY",
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "SATURDAY",
          "SUNDAY"
        ],
        isLiveLotto: false
      },
      {
        lotteryId: "c98c1a677dc541e88a3f8acb72e0852d",
        lotteryName: "ThuDo",
        lotteryEnName: "ThuDo",
        lotteryEnumName: "ThuDo",
        logo:
          "https://em.aseanlotto.com//image/2021/04/8155e262b1f2495881eeeb6d3c0305b3.png",
        description: "Lottery-ThuDo-Description",
        classType: {
          label: "北方彩种",
          value: "North Lottery"
        },
        isOpenToday: true,
        weekDay: [
          "THURSDAY",
          "FRIDAY",
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "SATURDAY",
          "SUNDAY"
        ],
        isLiveLotto: false
      },
      {
        lotteryId: "ea6e9ec7b2234c978c2380393d618c1a",
        lotteryName: "Mien Bac",
        lotteryEnName: "Mien Bac",
        lotteryEnumName: "Mien Bac",
        logo:
          "https://em.aseanlotto.com//image/2020/03/8c5f5181e2374b949e004885c360f309.png",
        description: "Lottery-MienBac-Description",
        classType: { label: "北方彩种", value: "North Lottery" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      },
      {
        lotteryId: "6e1a9669840844108a83054ab590faca",
        lotteryName: "Keno",
        lotteryEnName: "Keno",
        lotteryEnumName: "keno",
        logo:
          "https://em.aseanlotto.com//image/2020/05/955016280990422da332ce15003eb94a.png",
        description: "Lottery-Keno-Description",
        classType: { label: "Vietlott", value: "Vietlott" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      },
      {
        lotteryId: "b7a7fc3ced7a40c5a29953ffee3e8b43",
        lotteryName: "Max3D",
        lotteryEnName: "Max3D",
        lotteryEnumName: "Max3D",
        logo:
          "https://em.aseanlotto.com//image/2020/05/c4b2a13f7fe74b0dbcf1e122e08c93b3.png",
        description: "Lottery-Max3D-Description",
        classType: { label: "Vietlott", value: "Vietlott" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY", "FRIDAY", "MONDAY"]
      },
      {
        lotteryId: "0c99aa5fccd1431397a2abb8498a4f7d",
        lotteryName: "Mega",
        lotteryEnName: "Mega",
        lotteryEnumName: "Mega",
        logo:
          "https://em.aseanlotto.com//image/2020/05/cee7a352ed97497abebfa6b8dc3dddfb.png",
        description: "Lottery-Mega-Description",
        classType: { label: "Vietlott", value: "Vietlott" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY", "FRIDAY", "SUNDAY"]
      },
      {
        lotteryId: "08415cbc52dd486f9b62ff82f29153d5",
        lotteryName: "Power",
        lotteryEnName: "Power",
        lotteryEnumName: "Power",
        logo:
          "https://em.aseanlotto.com//image/2020/05/a3ee913ad0b94fb3961040881bb935b5.png",
        description: "Lottery-Power-Description",
        classType: { label: "Vietlott", value: "Vietlott" },
        isOpenToday: false,
        weekDay: ["THURSDAY", "SATURDAY", "TUESDAY"]
      },
      {
        lotteryId: "2f5a37ad045b4cfda5b92316a91d7d0f",
        lotteryName: "Max3D Pro",
        lotteryEnName: "Max3D Pro",
        lotteryEnumName: "Max3D Pro",
        logo:
          "https://em.aseanlotto.com//image/2021/12/4aa436d7c3ee499cb98085dd08590522.png",
        description: "Lottery-Max3D-Pro-Description",
        classType: {
          label: "Vietlott",
          value: "Vietlott"
        },
        isOpenToday: true,
        weekDay: ["TUESDAY", "THURSDAY", "SATURDAY"],
        isLiveLotto: false
      }
    ]
  },
  {
    classId: "68c2fc18406147d487bdf2abf8e94d7c",
    className: "中国",
    classEnName: "China",
    children: [
      {
        lotteryId: "4ff5c4179c574fa3a767225af922adaa",
        lotteryName: "双色球",
        lotteryEnName: "Shuang Se Lottery",
        lotteryEnumName: "Shuang Se Lottery",
        logo:
          "https://em.aseanlotto.com//image/2020/02/3bbf3a1649ef46a78036831b002b6326.png",
        description: "Lottery-ShuangSeLottery-Description",
        classType: { label: "福利彩票", value: "FuLi Lottery" },
        isOpenToday: false,
        weekDay: ["THURSDAY", "SUNDAY", "TUESDAY"]
      },
      {
        lotteryId: "901baa4cfcdb44268c7f31c19592734e",
        lotteryName: "福彩3D",
        lotteryEnName: "Fu Cai 3D",
        lotteryEnumName: "Fu Cai 3D",
        logo:
          "https://em.aseanlotto.com//image/2020/02/8ed0d24904f74316a9ba39f2b61abfc3.png",
        description: "Lottery-FuCai3D-Description",
        classType: { label: "福利彩票", value: "FuLi Lottery" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      },
      {
        lotteryId: "da49c876cbb64fb6abedba58949b7d92",
        lotteryName: "七乐彩",
        lotteryEnName: "Qi Le Lottery",
        lotteryEnumName: "Qi Le Lottery",
        logo:
          "https://em.aseanlotto.com//image/2020/02/68b2a7133a704a28beb6c41728a63b77.png",
        description: "Lottery-QiLeLottery-Description",
        classType: { label: "福利彩票", value: "FuLi Lottery" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY", "FRIDAY", "MONDAY"]
      },
      {
        lotteryId: "78a5269823104055943721013f8b283e",
        lotteryName: "大乐透",
        lotteryEnName: "Da Le Tou",
        lotteryEnumName: "Da Le Tou",
        logo:
          "https://em.aseanlotto.com//image/2020/02/204d33aaa0764293b86a7fa8cea8051c.png",
        description: "Lottery-DaLeTou-Description",
        classType: { label: "体育彩票", value: "Sport Lottery" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY", "SATURDAY", "MONDAY"]
      },
      {
        lotteryId: "ed53ade0abc7449b8b2adc01f2a92407",
        lotteryName: "排列5",
        lotteryEnName: "Pai Lie 5",
        lotteryEnumName: "Pai Lie 5",
        logo:
          "https://em.aseanlotto.com//image/2020/02/fc36d7586fe24c539bb9717236fc38e8.png",
        description: "Lottery-PaiLie5-Description",
        classType: { label: "体育彩票", value: "Sport Lottery" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      },
      {
        lotteryId: "e088c971e0e4413691c0512592a5a288",
        lotteryName: "七星彩",
        lotteryEnName: "Qi Xing Lottery",
        lotteryEnumName: "Qi Xing Lottery",
        logo:
          "https://em.aseanlotto.com//image/2020/02/d9210dfec59644359d84047de4ea882e.png",
        description: "Lottery-QiXingLottery-Description",
        classType: { label: "体育彩票", value: "Sport Lottery" },
        isOpenToday: false,
        weekDay: ["FRIDAY", "SUNDAY", "TUESDAY"]
      },
      {
        lotteryId: "bfefaa6980814c748243437d3a1a5261",
        lotteryName: "重庆时时彩",
        lotteryEnName: "Chong Qing Lottery",
        lotteryEnumName: "Chong Qing Lottery",
        logo:
          "https://em.aseanlotto.com//image/2020/02/b3c7ebf781804697bb5b96258223f431.png",
        description: "Lottery-ChongQingLottery-Description",
        classType: { label: "时时彩", value: "Constant Lottery" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY"]
      },
      {
        lotteryId: "10fb5c5fe0184efbac104dc5f3bdc728",
        lotteryName: "新疆时时彩",
        lotteryEnName: "XinJiang ShiShiCai",
        lotteryEnumName: "XinJiang ShiShi Lottery",
        logo:
          "https://em.aseanlotto.com///image/2021/02/fe3d91baa8ab461e8c31af751e73fcd0.png",
        description: "Lottery-XinJiang-ShiShi-Description",
        classType: {
          label: "时时彩",
          value: "Constant Lottery"
        },
        isOpenToday: true,
        weekDay: [
          "SUNDAY",
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY"
        ],
        isLiveLotto: false
      },
      {
        lotteryId: "a17ee6873d94440ab2d6e71228a5c35d",
        lotteryName: "山东11选5",
        lotteryEnName: "ShanDong 11/5",
        lotteryEnumName: "ShanDong 11选5 Lottery",
        logo:
          "https://em.aseanlotto.com///image/2021/02/4392319f991241b2a58476a70347c661.png",
        description: "Lottery-ShanDong-11-5-Description",
        classType: {
          label: "时时彩",
          value: "Constant Lottery"
        },
        isOpenToday: true,
        weekDay: [
          "SUNDAY",
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY"
        ],
        isLiveLotto: false
      },
      {
        lotteryId: "c7a4b82bcc874fa786081c0ddca54349",
        lotteryName: "广东11选5",
        lotteryEnName: "GuangDong 11/5",
        lotteryEnumName: "GuangDong 11选5 Lottery",
        logo:
          "https://em.aseanlotto.com///image/2021/02/185a85b3eedb4af0b72a69455f193f64.png",
        description: "Lottery-GuangDong-11-5-Description",
        classType: {
          label: "时时彩",
          value: "Constant Lottery"
        },
        isOpenToday: true,
        weekDay: [
          "SUNDAY",
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY"
        ],
        isLiveLotto: false
      }
    ]
  },
  {
    classId: "9a44ba520ff7408bac4e6cc2df845c7a",
    className: "泰国",
    classEnName: "Thailand",
    children: [
      {
        lotteryId: "ab795d6680314e74b8f33e4672f4741b",
        lotteryName: "Thai Lotto",
        lotteryEnName: "Thai Lotto",
        lotteryEnumName: "Thai Lotto",
        logo:
          "https://em.aseanlotto.com//image/2020/02/ad455901242740a19dc67fece58b9618.png",
        description: "Lottery-ThaiLotto-Description",
        classType: { label: "泰国彩票", value: "thai lotto" },
        isOpenToday: false,
        weekDay: [
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "SATURDAY",
          "SUNDAY"
        ]
      },
      {
        lotteryId: "ce12f9765fe24f6b9142f0302ce26f97",
        lotteryName: "Thai Lotto VIP",
        lotteryEnName: "Thai Lotto VIP",
        lotteryEnumName: "Thai Lotto VIP",
        logo:
          "https://em.aseanlotto.com//image/2020/03/6385e2709daa45cca6dd8d1fb6635c15.png",
        description: "Lottery-ThaiLottoVip-Description",
        classType: { label: "泰国彩票", value: "thai lotto" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      }
    ]
  },
  {
    classId: "b39ed9b1b9264a61a92ec6e130ffe58e",
    className: "老挝",
    classEnName: "Laos",
    children: [
      {
        lotteryId: "3dc60e9731b44cac8e7506d8eaccf088",
        lotteryName: "ຫວຍພັດທະນາ",
        lotteryEnName: "Laos Lotto",
        lotteryEnumName: "Laos Lotto",
        logo:
          "https://em.aseanlotto.com//image/2020/02/dd3ebea46a264c35b57edb8eb34c37ce.png",
        description: "Lottery-LaosLotto-Description",
        classType: { label: "老挝彩票", value: "laos lotto" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      }
    ]
  },
  {
    classId: "035f796a275e43ca9b4d5de45a3230ca",
    className: "新加坡",
    classEnName: "Singapore",
    children: [
      {
        lotteryId: "4dd09d80bb3343e0bea7385d16e8425e",
        lotteryName: "Singapore 4D",
        lotteryEnName: "Singapore 4D",
        lotteryEnumName: "Singapore 4D",
        logo:
          "https://em.aseanlotto.com//image/2020/02/defc44f6e8524c7d9612e625d3bc7374.png",
        description: "Lottery-Singapore4D-Description",
        classType: { label: "4D", value: "4D" },
        isOpenToday: false,
        weekDay: ["WEDNESDAY", "SATURDAY", "SUNDAY"]
      },
      {
        lotteryId: "ce48705117df4418b3026f7936ed4dd3",
        lotteryName: "Singapore TOTO",
        lotteryEnName: "Singapore TOTO",
        lotteryEnumName: "Singapore TOTO",
        logo:
          "https://em.aseanlotto.com//image/2020/02/a073e42d7007456aa74397bf61df3479.png",
        description: "Lottery-SingaporeTOTO-Description",
        classType: { label: "TOTO", value: "TOTO" },
        isOpenToday: false,
        weekDay: ["THURSDAY", "MONDAY"]
      }
    ]
  },
  {
    classId: "a00f916bb948461ab02215c1dc11596d",
    className: "马来西亚",
    classEnName: "Malaysia",
    children: [
      {
        lotteryId: "b771d9e3e73b432fa2d166836947c383",
        lotteryName: "DaMaCai",
        lotteryEnName: "DaMaCai",
        lotteryEnumName: "DaMaCai",
        logo:
          "https://em.aseanlotto.com//image/2020/02/72de810d326c42fb836c74362653bbe8.png",
        description: "Lottery-DaMaCai-Description",
        classType: { label: "马来西亚4D", value: "Malay Lotto" },
        isOpenToday: false,
        weekDay: ["WEDNESDAY", "SATURDAY", "SUNDAY"]
      },
      {
        lotteryId: "6f289c9fffd44bb88bc6d5ee698bda23",
        lotteryName: "GD Lottery",
        lotteryEnName: "GD Lottery",
        lotteryEnumName: "GD Lottery",
        logo:
          "https://em.aseanlotto.com//image/2020/02/3d5015240c284a3a848089d24d37a68f.png",
        description: "Lottery-GDLottery-Description",
        classType: { label: "马来西亚4D", value: "Malay Lotto" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      },
      {
        lotteryId: "5316aeaf022942ee997bcc7ee329bd85",
        lotteryName: "Magnum Lottery",
        lotteryEnName: "Magnum Lottery",
        lotteryEnumName: "Magnum Lottery",
        logo:
          "https://em.aseanlotto.com//image/2020/02/b1dd1ae791be4f53b5466689369ddefe.png",
        description: "Lottery-MagnumLottery-Description",
        classType: { label: "马来西亚4D", value: "Malay Lotto" },
        isOpenToday: false,
        weekDay: ["WEDNESDAY", "SATURDAY", "SUNDAY"]
      },
      {
        lotteryId: "afb65493eafc417fb1f30e4f0e089bac",
        lotteryName: "Malay TOTO",
        lotteryEnName: "Malay TOTO",
        lotteryEnumName: "Malay TOTO",
        logo:
          "https://em.aseanlotto.com//image/2020/02/fa0d7dd4b4eb487bba7817fbefc3dbd6.png",
        description: "Lottery-MalayTOTO-Description",
        classType: { label: "马来西亚4D", value: "Malay Lotto" },
        isOpenToday: false,
        weekDay: ["WEDNESDAY", "SATURDAY", "SUNDAY"]
      },
      {
        lotteryId: "83df0448b03148e98ed0e2b24228319a",
        lotteryName: "Sabah Lottery",
        lotteryEnName: "Sabah Lottery",
        lotteryEnumName: "Sabah Lottery",
        logo:
          "https://em.aseanlotto.com//image/2020/02/666ed9a221244b7099d8d22d3289a5c4.png",
        description: "Lottery-SabahLottery-Description",
        classType: { label: "马来西亚4D", value: "Malay Lotto" },
        isOpenToday: false,
        weekDay: ["WEDNESDAY", "SATURDAY", "SUNDAY"]
      },
      {
        lotteryId: "a13e17f38b704d809c23914a8158fdda",
        lotteryName: "Sadakan Lottery",
        lotteryEnName: "Sadakan Lottery",
        lotteryEnumName: "Sadakan Lottery",
        logo:
          "https://em.aseanlotto.com//image/2020/02/dec0638826a94444a04fa6b547357e90.png",
        description: "Lottery-SadakanLottery-Description",
        classType: { label: "马来西亚4D", value: "Malay Lotto" },
        isOpenToday: false,
        weekDay: ["WEDNESDAY", "SATURDAY", "SUNDAY"]
      },
      {
        lotteryId: "a1e0cd42eb2b4db1be6368c60746628d",
        lotteryName: "Sarawak Lottery",
        lotteryEnName: "Sarawak Lottery",
        lotteryEnumName: "Sarawak Lottery",
        logo:
          "https://em.aseanlotto.com//image/2020/02/56f43bc3bff747918ea85d8a3652b15b.png",
        description: "Lottery-SarawakLottery-Description",
        classType: { label: "马来西亚4D", value: "Malay Lotto" },
        isOpenToday: false,
        weekDay: ["WEDNESDAY", "SATURDAY", "SUNDAY"]
      }
    ]
  },
  {
    classId: "2c92ceda13a246639afaee51dff8a01e",
    className: "菲律宾",
    classEnName: "Philippines",
    children: [
      {
        lotteryId: "87f57d559dc64c5496a3ed4596f52620",
        lotteryName: "Manila 1 min",
        lotteryEnName: "Manila 1 min",
        lotteryEnumName: "Manila 1 min",
        logo:
          "https://em.aseanlotto.com//image/2020/02/4b115a0004a64e9b9db8ceecd4c93632.png",
        description: "Lottery-Manila1min-Description",
        classType: { label: "分分彩", value: "Minute Lotto" },
        isOpenToday: true,
        weekDay: ["WEDNESDAY"]
      }
    ]
  },
  {
    classId: "7a2beacff5fb4a3b81cc981b400d5a99",
    className: "在线彩种",
    classEnName: "Live Lotto",
    promotionIcon: locationIcon,
    children: [
      {
        lotteryId: "39e4c430f68842d799d27b4ba6cfebb9",
        lotteryName: "Live Lotto 2 Min",
        lotteryEnName: "Live Lotto 2 Min",
        lotteryEnumName: "Live Lotto 2 Min",
        hasLiveVideo: true,
        hasVide: true,
        logo:
          "https://em.aseanlotto.com//image/2021/02/7dabfe1afcec4e14bb054ab2ee8e6f42.png",
        description: "Live-Lottery-Description",
        classType: { label: "分分彩", value: "Minute Lotto" },
        isOpenToday: true,
        weekDay: [
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
          "MONDAY",
          "TUESDAY"
        ]
      }
    ]
  }
];

export default lotteries;
