import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import theme from "assets/jss/material-ui-theme";
import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

import MainLayout from "./MainLayout";

// i18n
import "./i18n";

const customMaterialUITheme = createMuiTheme(theme);

ReactDOM.render(
  <MuiThemeProvider theme={customMaterialUITheme}>
    <Suspense fallback="loading...">
      <MainLayout />
    </Suspense>
  </MuiThemeProvider>,
  document.getElementById("root")
);
