import React from "react";

// pages for this product
const HomePage = React.lazy(() => import("views/HomePage/HomePage.js"));
const PartnersPage = React.lazy(() =>
  import("views/PartnersPage/PartnersPage")
);
const CountryLotteriesPage = React.lazy(() =>
  import("views/CountryLottriesPage/CountryLotteriesPage")
);
const APIPage = React.lazy(() => import("views/APIPage/APIPage"));
const ContactUsPage = React.lazy(() =>
  import("views/ContactUsPage/ContactUsPage")
);
const LiveResultPage = React.lazy(() =>
  import("views/LiveResultPage/LiveResultPage")
);
const PastResultsPage = React.lazy(() =>
  import("views/PastResultsPage/PastResultsPage")
);
const TrendingResearchPage = React.lazy(() =>
  import("views/TrendingResearchPage/TrendingResearchPage")
);
const FPayPage = React.lazy(() => import("views/FPayPage/FPayPage"));
const LotoPhPage = React.lazy(() => import("views/LotoPhPage/LotoPhPage"));

const routes = [
  {
    path: "/trending-research/:lotteryId",
    component: TrendingResearchPage
  },
  {
    path: "/contact-us",
    component: ContactUsPage
  },
  {
    path: "/lottery-api",
    component: APIPage
  },
  {
    path: "/partners/loto-ph",
    component: LotoPhPage
  },
  {
    path: "/partners/payment/f-pay",
    component: FPayPage
  },
  {
    path: "/partners/country/:countryName",
    component: PartnersPage
  },
  {
    path: "/partners/:partnerId",
    component: PartnersPage
  },
  {
    path: "/live-results/:lotteryName",
    component: LiveResultPage
  },
  {
    path: "/past-results/:lotteryName/:scheduleId",
    component: LiveResultPage
  },
  {
    path: "/past-results/:lotteryName",
    component: PastResultsPage
  },
  {
    path: "/lotteries/:country",
    component: CountryLotteriesPage
  },
  {
    path: "/",
    component: HomePage
  }
];

export default routes;
