import { primaryColor } from "assets/jss/material-kit-pro-react";

const theme = {
  palette: {
    primary: {
      main: primaryColor[0]
    }
  }
};

export default theme;
