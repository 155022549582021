import {
  container,
  containerFluid,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  drawerWidth,
  blackColor,
  whiteColor,
  grayColor,
  lightGreenColor,
  hexToRgb,
  mlAuto
} from "assets/jss/material-kit-pro-react.js";

const headerStyle = theme => ({
  appBar: {
    display: "flex",
    border: "0",
    // borderRadius: "3px",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: grayColor[15],
    width: "100%",
    backgroundColor: whiteColor,
    boxShadow:
      "0 4px 18px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative"
  },
  absolute: {
    position: "absolute",
    top: "auto"
  },
  fixed: {
    position: "fixed"
  },
  container: {
    ...container,
    minHeight: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap"
  },
  containerFluid: {
    ...containerFluid,
    minHeight: "50px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap"
  },
  title: {
    letterSpacing: "unset",
    "&,& a": {
      ...defaultFont,
      minWidth: "unset",
      lineHeight: "30px",
      fontSize: "18px",
      borderRadius: "3px",
      textTransform: "none",
      whiteSpace: "nowrap",
      color: "inherit",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent"
      }
    }
  },
  appResponsive: {
    margin: "20px 10px",
    marginTop: "0px"
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.46)"
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.46)"
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.46)"
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.46)"
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.46)"
  },
  rose: {
    backgroundColor: roseColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.46)"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: whiteColor
  },
  dark: {
    color: whiteColor,
    backgroundColor: grayColor[9] + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(grayColor[9]) +
      ", 0.46)"
  },
  darkTransparent: {
    color: whiteColor,
    backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)"
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: grayColor[15],
    backgroundColor: whiteColor + " !important",
    boxShadow:
      "0 4px 18px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.15)"
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "auto",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition,
    backgroundColor: "#212526",
    color: whiteColor
  },
  hidden: {
    width: "100%"
  },
  collapse: {
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto"
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center"
  },
  closeButtonDrawer: {
    position: "absolute",
    right: "8px",
    top: "9px",
    zIndex: "1"
  },

  list: {
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      display: "block"
    },
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0",
    width: "100%"
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "auto",
        backgroundColor: "#212526",
        "& > li": {
          color: whiteColor,
          "& > a": {
            fontSize: "14px",
            textTransform: "capitalize"
          },
          "&:last-child:after": {
            height: "0"
          }
        }
      },
      width: "100%",
      // "&:not(:last-child)": {
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: grayColor[14]
      }
    }
    // }
  },
  locationListItem: {
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "unset",
        overflow: "auto"
      }
    }
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "16px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus,&:active": {
      // color: "inherit"
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)"
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem"
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px"
    }
  },
  navLinkActive: {
    "&, &:hover, &:focus,&:active ": {
      color: "inherit",
      position: "relative",
      padding: "0.9375rem",
      fontWeight: "400",
      fontSize: "16px",
      textTransform: "uppercase",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex",
      "&:hover,&:focus": {
        color: "inherit"
      },
      textShadow: "1px 0px 0px"
      // backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)"
    },
    "&:hover, &:focus,&:active": {
      backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.1)"
    }
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "14px",
    opacity: "0.5",
    marginTop: "-4px",
    top: "1px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative"
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "flex",
      padding: "0.75rem 1.25rem 0.75rem 0.75rem"
    }
  },
  mlAuto,
  navSheetLocation: {
    position: "absolute",
    left: "200px",
    width: "200px",
    top: "100%",
    backgroundColor: "#212526",
    zIndex: "12",
    overflow: "hidden"
  },
  navSheet: {
    position: "absolute",
    left: "calc(50% - 500px)",
    width: "1000px",
    top: "100%",
    backgroundColor: "#212526",
    zIndex: "12",
    overflow: "hidden"
  },
  countryFlag: {
    width: "28px",
    marginRight: "16px"
  },
  liveResultCountryFlag: {
    width: "18px",
    height: "18px",
    marginRight: "auto",
    borderRadius: "50%",
    objectFit: "cover"
  },
  countryButton: {
    padding: "8px",
    fontSize: "14px",
    textTransform: "capitalize",
    width: "100%",
    justifyContent: "flex-start",
    "&:hover": {
      color: "#C19755"
    }
  },
  locationList: {
    // marginLeft: "160px",
    height: "390px",
    "& li": {
      paddingTop: "5px",
      paddingBottom: "5px",
      "& a": {
        // paddingTop: "6px",
        // paddingBottom: "6px"
      }
    }
  },
  liveResultMenuWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    height: "375px"
  },
  liveResultCountries: {
    width: "200px"
  },
  liveResultLotteries: {
    flex: "1",
    backgroundColor: "#262B2D",
    alignSelf: "stretch"
  },
  rightAlignList: {
    "& a": {
      flex: 1,
      marginLeft: "16px"
    }
  },
  multiColumnList: {
    display: "inline-flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxHeight: "100%",
    position: "relative",
    "&.withDay": {
      marginLeft: "62px",
      height: "100%"
    },
    "& > li": {
      width: "unset",
      minWidth: "180px",
      position: "relative",
      paddingLeft: "50px",
      "&.nestedLi": {
        padding: "0"
      },
      "&:not(.nestedLi):hover a": {
        color: lightGreenColor,
        "&:before": {
          content: `""`,
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: lightGreenColor,
          position: "absolute",
          left: "-30px",
          top: "50%",
          transform: "translateY(-50%)"
        }
      }
    }
  },
  horizontalList: {
    display: "flex",
    padding: 0,
    minHeight: "40px",
    flexWrap: "wrap",
    "& > li": {
      minWidth: "140px",
      position: "relative",
      paddingLeft: "30px",
      width: "unset",
      "&:hover a": {
        color: lightGreenColor,
        "&:before": {
          content: `""`,
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: lightGreenColor,
          position: "absolute",
          left: "-20px",
          top: "50%",
          transform: "translateY(-50%)"
        }
      }
    }
  },
  daySelect: {
    position: "absolute",
    left: "-62px",
    top: "0",
    width: "60px",
    height: "100%",
    backgroundColor: primaryColor[0],
    fontSize: "12px",
    color: "#BFBFBF",
    "& li": {
      cursor: "pointer",
      paddingLeft: "12px",
      position: "relative"
    }
  },
  selectedVietLotDay: {
    color: whiteColor,
    "&:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      right: 0,
      transform: "translateY(-50%)",
      width: 0,
      height: 0,
      borderTop: "6px solid transparent",
      borderRight: "8px solid #262B2D",
      borderBottom: "6px solid transparent"
    }
  },
  lotteryTypeMenu: {
    display: "flex",
    paddingBottom: 0,
    borderBottom: "1px solid #C19755",
    paddingLeft: "30px",
    "& li": {
      width: "unset",
      minWidth: "145px",
      padding: "0 0 0 30px",
      color: grayColor[26],
      fontSize: "14px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      "&:first-child > span": {
        backgroundColor: primaryColor[0]
      },
      "& > span": {
        position: "relative",
        padding: "4px 20px",
        overflow: "hidden",
        borderBottom: "1px solid #C19755",
        marginBottom: "-1px",
        transition: "all 0.3s",
        cursor: "pointer",
        minWidth: "110px",
        textAlign: "center",
        "&:before": {
          content: '""',
          position: "absolute",
          left: "0",
          top: "100%",
          width: "calc(100% - 2px)",
          height: "100%",
          border: "1px solid #C19755",
          transition: "all 0.3s"
        },
        "&.active": {
          borderBottom: "1px solid #262B2D",
          "&:before": {
            top: "0"
          }
        }
      }
    }
  },
  navSheetItem: {
    "&.active": {
      backgroundColor: "#262B2D"
    }
  },
  navSheetLink: {
    color: "inherit",
    position: "relative",
    padding: "0px 0",
    fontWeight: "400",
    fontSize: "14px",
    // textTransform: "uppercase",
    height: "24px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    alignItems: "center",
    "&:hover,&:focus": {
      color: "inherit"
    },
    "&.active": {
      color: lightGreenColor
    }
  },
  expandIcon: {
    transition: "transform 0.3s",
    "&.expanded": {
      transform: "rotateZ(180deg)"
    }
  },
  locationButton: {
    padding: "12px 16px",
    width: "190px",
    justifyContent: "row",
    "&:hover,&:focus,&:active": {
      // color: "inherit"
      backgroundColor: primaryColor[0]
    },
    "& img": {
      marginRight: "16px"
    },
    "& $countryFlag": {
      border: "1px solid #FFF"
    }
  },
  flagWithName: {
    marginRight: "auto",
    "& $countryFlag": {
      height: "26px",
      width: "unset"
    }
  },
  brandLogo: {
    width: "164px",
    height: "32px"
  }
});

export default headerStyle;
